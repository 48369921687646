<template>
  <div>
    <div class="stepper">
      <el-steps
        :active="stepIndex"
        finish-status="success"
        :space="300"
      >
        <el-step
          v-for="step in steps"
          :key="step.label"
          :title="step.label"
        />
      </el-steps>
    </div>

    <div class="importer">
      <div class="action-panel flex-item">
        <div v-show="errors">
          <el-alert
            class="error-list"
            show-icon
            type="error"
            v-html="errors"
          />
        </div>
        <div class="content">
          <keep-alive>
            <component
              :is="currentStageComponent"
              ref="currentComponent"
              :busy.sync="busy"
              :data="data"
              @clear-errors="validationErrors = null"
              @complete="handleCompletion"
            />
          </keep-alive>
        </div>
        <div class="buttons">
          <el-button
            :disabled="busy || stepIndex === 0"
            @click="handleClickPrev"
          >
            Previous
          </el-button>
          <el-button
            :loading="busy"
            :disabled="busy"
            type="primary"
            @click="handleClickNext"
          >
            {{ finalStep ? 'Finish' : 'Next' }}
          </el-button>
        </div>
      </div>

      <div
        v-if="steps[stepIndex].instructions"
        class="info-panel flex-item"
      >
        <el-alert
          :closable="false"
          :description="steps[stepIndex].instructions"
          show-icon
          title="Instructions"
          type="info"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import GeoframeImporterUpload from './GeoframeImporterUpload.vue';
import GeoframeImporterName from './GeoframeImporterName.vue';
import GeoframeImporterReview from './GeoframeImporterReview.vue';
import { geoframe } from '@/adonis-api';
import { dateToString } from '@/helpers';

export default {
  components: {
    GeoframeImporterUpload,
    GeoframeImporterName,
    GeoframeImporterReview,
  },

  data() {
    return {
      busy: false,
      data: {},
      dateRange: [],
      namingPattern: '',
      organizationId: null,
      stepIndex: 0,
      steps: [
        {
          label: 'Upload Geo Data',
          component: 'GeoframeImporterUpload',
          instructions: `Upload your geographical data. It can be GeoJSON, KML,
            or a ZIP file containing any combination of GeoJSON/KML files.`,
        },
        {
          label: 'Name and Tag',
          component: 'GeoframeImporterName',
          instructions: `Choose a naming pattern for your imported data set.
            The tokens are properties that we were able to pull in from the
            geodata you uploaded, and should be used to generate a unique name
            for each geoframe.`,
        },
        {
          label: 'Review',
          component: 'GeoframeImporterReview',
        },
      ],
      tags: [],
      validationErrors: null,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart']),

    currentStageComponent() {
      return this.steps[this.stepIndex].component;
    },

    errors() {
      if (!this.validationErrors || this.validationErrors.length === 0) {
        return null;
      }

      let errorText = 'Please address the following errors:<ul>';

      for (const err of this.validationErrors) {
        errorText += `<li>${err}</li>`;
      }

      return errorText + '</ul>';
    },

    finalStep() {
      return this.stepIndex === this.steps.length - 1;
    },
  },

  methods: {
    async handleClickNext() {
      this.validationErrors = await this.$refs.currentComponent.validate();
      if (this.validationErrors && this.validationErrors.length > 0) return;

      await this.$refs.currentComponent.completeAction();

      if (this.stepIndex === this.steps.length - 1) {
        this.initiateImport();
        return;
      }
      if (!this.$refs.currentComponent.error) {
        this.stepIndex++;
      }
    },

    handleClickPrev() {
      if (this.stepIndex > 0) this.stepIndex--;
    },

    handleCompletion(data) {
      switch (this.stepIndex) {
        case 0:
          this.data = data;
          break;
        case 1:
          this.namingPattern = data.namingPattern;
          this.organizationId = data.organizationId;
          this.dateRange = data.dateRange;
          this.tags = data.tags;
          break;
        default:
          break;
      }
    },

    async initiateImport() {
      this.busy = true;

      try {
        const result = await geoframe.import({
          uuid: this.data.uuid,
          historical: moment(this.dateRange[0]).isBefore(this.rangeStart),
          namingPattern: this.namingPattern,
          organizationId: this.organizationId,
          start: dateToString(this.dateRange[0]),
          end: dateToString(this.dateRange[1]),
          tags: this.tags,
        });

        window.mixpanel.track('Geoframe Import', {
          UUID: this.data.uuid,
          Historical: moment(this.dateRange[0]).isBefore(this.rangeStart),
          'Naming Pattern': this.namingPattern,
          Start: dateToString(this.dateRange[0]),
          End: dateToString(this.dateRange[1]),
          Tags: this.tags,
        });

        this.$message({
          message: result.message,
          type: result.status,
        });

        this.busy = false;

        if (result.status === 'success') {
          this.$router.push('/geoframes/library');
        }
      } catch (e) {
        this.$reportError(e);
        this.busy = false;
      }
    },
  },
};
</script>

<style scoped>
.importer {
  display: flex;
  padding: 8px;
}

.flex-item {
  margin: 8px;
}

.action-panel {
  flex: 1;
}

.info-panel {
  flex: 1;
}

.error-list {
  display: block;
  font-size: 0.8em;
  padding-left: 1.5em;
  padding-top: 1.5em;
  margin-bottom: 20px;
}

.buttons {
  margin-top: 20px;
}
</style>
