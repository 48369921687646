<template>
  <div class="review">
    <div class="count-summary">
      <div class="count">
        <div class="label">
          Imported Features
        </div>
        <div class="num pass">
          {{ data.importable }}
        </div>
      </div>
      <div class="count">
        <div class="label">
          Invalid Features
        </div>
        <div :class="failedImportsClass">
          {{ failedImports }}
        </div>
      </div>
    </div>

    <div
      v-if="failedImports"
      class="report error-report"
    >
      <div class="explanation">
        {{ failedImports }} feature(s) failed to import. Click the button below
        to download a GeoJSON file which contains these invalid features, which
        you may correct and re-import later. Each feature's "properties" object
        contains a detailed error message.
      </div>
      <div class="file-link">
        <el-button
          type="mini"
          @click="handleDownloadClick"
        >
          Download Invalid Features
        </el-button>
      </div>
    </div>

    <div
      v-if="data.errors && data.errors.length > 0"
      class="report error-report"
    >
      <div class="explanation">
        The following files completely failed to import due to malformed
        structure.
      </div>
      <div class="bad-file-list">
        <ul>
          <li
            v-for="error in data.errors"
            :key="error.filePath"
          >
            {{ error.filePath }}
          </li>
        </ul>
      </div>
    </div>

    <div class="report preview-report">
      Click "Finish" below to import and create {{ data.importable }} geoframes.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, required: true },
  },

  computed: {
    failedImports() {
      return this.data.total_parsed - this.data.importable;
    },

    failedImportsClass() {
      return {
        num: true,
        fail: this.failedImports > 0,
      };
    },
  },

  methods: {
    completeAction() {},
    handleDownloadClick() {
      window.location.href = this.data.s3Url;
    },

    validate() {
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.review {
  max-width: 620px;
}

.count-summary {
  display: flex;
}

.count {
  color: #888;
  flex: 1;
  text-align: center;

  .label {
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
  }

  .num {
    font-size: 4em;

    &.pass {
      color: $--color-success;
    }
    &.fail {
      color: $--color-danger;
    }
  }
}

.report {
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 1.5;
  padding: 20px;
  margin-top: 20px;
}

.error-report {
  background: lighten($--color-danger, 25%);
  border: 1px solid $--color-danger;
  color: darken($--color-danger, 20%);

  ul {
    margin-bottom: 0;
  }

  .file-link {
    margin-top: 20px;
  }
}

.preview-report {
  background: lighten($--color-success, 25%);
  border: 1px solid $--color-success;
  color: darken($--color-success, 20%);
}
</style>
