<template>
  <div>
    <el-form>
      <el-form-item label="Naming Pattern">
        <el-input
          ref="nameInput"
          v-model="name"
        />
      </el-form-item>
      <div class="tokens">
        <span class="label">
          Tokens:
        </span>
        <el-tag
          size="small"
          @click.native="handleTokenClick"
        >
          #
        </el-tag>
        <el-tag
          v-for="token in data.tokens"
          :key="token"
          size="small"
          @click.native="handleTokenClick"
        >
          {{ token }}
        </el-tag>
      </div>
      <div class="samples">
        <div class="note">
          Below is a sample of how your data will be named according to what you
          enter above. Click the tokens above to insert them into the name
          field. The "#" token automatically inserts the record index number.
        </div>
        <div class="list">
          <ul v-show="!!name">
            <li
              v-for="(sample, index) in data.samples"
              :key="sample.name"
            >
              {{ performSubs(sample.name, sample, index) }}
            </li>
          </ul>
          <div v-show="!name">
            <p>(start typing a name in the field above)</p>
          </div>
        </div>
      </div>
      <el-form-item label="Tags">
        <tag-picker :tags.sync="tags" />
      </el-form-item>
      <el-form-item label="Organization">
        <org-picker :organization.sync="organization" />
      </el-form-item>
      <el-form-item label="Date Range">
        <div>
          <date-picker
            :date-range.sync="dateRange"
            :organization="organization"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import substitute from 'token-substitute';
import TagPicker from '../global/TagPicker.vue';
import OrgPicker from '../global/OrgPicker.vue';
import DatePicker from '../global/DatePicker.vue';

export default {
  components: { DatePicker, OrgPicker, TagPicker },
  props: {
    busy: { type: Boolean, required: true },
    data: { type: Object, required: true },
  },

  data() {
    return {
      dateRange: [],
      name: '',
      organization: this.$store.state.user.orgDetails,
      tags: [],
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission']),

    historicalAccess() {
      return (
        this.hasPermission('historical_access') &&
        this.organization.historicalAccess
      );
    },
  },

  created() {
    this.subOptions = {
      prefix: '[',
      suffix: ']',
    };
  },

  methods: {
    completeAction() {
      this.$emit('complete', {
        namingPattern: this.name,
        organizationId: this.organization.id,
        dateRange: this.dateRange,
        tags: this.tags,
      });
    },

    handleTokenClick({ target }) {
      const input = this.$refs.nameInput.$el.querySelector('input');

      if (!input) return;

      const oldSelStart = input.selectionStart;
      const oldLength = this.name.length;
      const front = input.value.substring(0, input.selectionStart);
      const back = input.value.substring(
        input.selectionStart,
        input.value.length,
      );

      this.name =
        (/\s$/.test(front) || front.length === 0 ? front : `${front} `) +
        `[${target.innerText}]` +
        (/^\s/.test(back) || back.length === 0 ? back : ` ${back}`);

      this.$nextTick(() => {
        this.$refs.nameInput.focus();
        input.setSelectionRange(
          oldSelStart + this.name.length - oldLength,
          oldSelStart + this.name.length - oldLength,
        );
      });
    },

    performSubs(name, sampleData, index) {
      return substitute(this.name, {
        ...this.subOptions,
        tokens: {
          ...sampleData,
          '#': index + 1,
        },
      });
    },

    validate() {
      const errors = [];
      const substitutions = this.data.samples.map((sample, index) =>
        this.performSubs(sample.name, sample, index),
      );

      if (
        substitutions.length > 1 &&
        substitutions.every(sub => sub === substitutions[0])
      ) {
        errors.push(
          'Your naming pattern would result in duplicate names. ' +
            'Please try adding more tokens that result in unique results.',
        );
      }
      if (!/\[\S+\]/.test(this.name)) {
        errors.push(
          'You must use at least one token so each geoframe has a unique name.',
        );
      }
      if (this.dateRange.length !== 2) {
        errors.push('You must select a date range.');
      }

      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.samples {
  border-radius: 4px;
  font-size: 0.8em;
  border: 1px solid #ccc;
  background-color: #eee;
  padding: 20px;
  margin-bottom: 20px;
}

.tokens {
  margin-bottom: 20px;
  margin-top: -12px;

  .label {
    font-size: 0.8em;
    margin-right: 8px;
  }

  > span {
    cursor: pointer;
  }

  > span:not(:last-child) {
    margin-right: 2px;
  }
}
</style>
