<template>
  <div>
    <uploader
      ref="uploader"
      :accept-file-exts="fileExts"
      api-endpoint="/api/v1/geoframes/import/geojson"
      upload-field-name="imports"
      @complete="data => $emit('complete', data)"
      @error="error => handleError(error)"
      @file-change="handleFileChange"
      @upload-state-change="handleStateChange"
    />
  </div>
</template>

<script>
import Uploader from '../global/Uploader.vue';

export default {
  components: {
    Uploader,
  },

  props: {
    busy: { type: Boolean, required: true },
  },

  data() {
    return {
      error: false,
      fileList: [],
      fileExts: [
        '.geojson',
        '.kml',
        '.kmz',
        '.zip',
      ],
    };
  },

  methods: {
    async completeAction() {
      await this.$refs.uploader.startUpload();
    },

    handleError(error) {
      this.error = true;
      this.$notify.error({
        title: 'Unable to Process',
        ...error,
        duration: 10000,
      });
    },

    handleFileChange(fileList) {
      this.fileList = fileList;
      this.error = false;
    },

    handleStateChange(isUploading) {
      this.$emit('update:busy', isUploading);
    },

    async validate() {
      return this.fileList.length > 0
        ? null
        : ['Please select at least one file to upload.'];
    },
  },
};
</script>

<style>
</style>
