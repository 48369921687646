<template>
  <div>
    <geoframe-importer />
  </div>
</template>

<script>
import GeoframeImporter from '../../geoframe/GeoframeImporter.vue';

export default {
  components: { GeoframeImporter },
};
</script>
